import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Station Deployments</AnchorLink>
  </AnchorLinks>
  <AnchorLink mdxType="AnchorLink">Bug Fixes</AnchorLink>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h2>{`Server Deployments`}</h2>
    <p>{`Instance Details button`}</p>
    <ul>
      <li parentName="ul">{`Export data to PDF/CSV format. You can export Inventory data from managed instance.`}</li>
    </ul>
    <p>{`Metadata Details:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Memory`}</inlineCode>{` (List memory on a device)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CPU`}</inlineCode>{` (List CPU Cores, speed and sockets.)
Execute Actions - pre-populate parameters`}</li>
      <li parentName="ul">{`Populate parameter values in Execute Actions window based on Deployment Profiles parameters.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.09.04%252FParamExecuteAction.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img>{`
Execute Workflow window - update display size.`}</li>
      <li parentName="ul">{`Update display size on Workflow window.`}</li>
    </ul>
    <h2>{`Station Deployments`}</h2>
    <p>{`Add Executed By to Station deployments page.`}</p>
    <h2>{`Bug Fixes`}</h2>
    <ul>
      <li parentName="ul">{`Download logs - CAPE-954`}</li>
      <li parentName="ul">{`Delete Inactive Deployment - CAPE-988`}</li>
      <li parentName="ul">{`Step Status takes a long time to update - CAPE-970`}</li>
      <li parentName="ul">{`Execute Actions window status does not update - CAPE-1034`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      